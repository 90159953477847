import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { LazyLoadImage } from 'react-lazy-load-image-component'
// import About__header from "../images/about_main.jpg"


import "animate.css/animate.min.css"


export default class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            About CueForGood & Our Culture </title>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=5.0, minimum-scale=1.0" />
            <link rel="canonical" href={this.state.url}/>
            <meta name="description"
                  content="Rooted in Chandigarh, India, CueForGood is a Shopify partner & Magento 2 trained agency aiming to empower purpose-driven online retailers through web design, development, and marketing."/>
            <meta property="og:site_name" content="CueForGood"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content={this.state.url}/>
            <meta property="og:title"
                  content="About CueForGood & Our Culture"/>
            <meta property="og:description"
                  content="Rooted in Chandigarh, India, CueForGood is a Shopify partner & Magento 2 trained agency aiming to empower purpose-driven online retailers through web design, development, and marketing."/>
            <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg"/>

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description"
            content="Rooted in Chandigarh, India, CueForGood is a Shopify partner & Magento 2 trained agency aiming to empower purpose-driven online retailers through web design, development, and marketing." />
          <meta name="twitter:title"
            content="About CueForGood & Our Culture" />
          <meta name="twitter:site" content="@CueForGood" />
          <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg" />
        </Helmet>

        <section className="home-slider__row">
        
        <div className="container">
            <div className="line1 line"></div>
            <div className="line2 line"></div>
            <div className="homepage-title">
                <h1>CueForGood is driven by the ethos of ‘service’ & making a positive impact in this world.</h1>
                <h4 className="homeslider-header">We consciously work with brands, companies, eCommerce businesses, start-ups as well as purpose-driven organizations that care about the Planet and People equally or more than just Profits.</h4>
          </div>
        </div>
      </section>

      </Layout>
    )
  }
}